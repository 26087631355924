import { Component, Vue, Prop } from 'vue-property-decorator';

import template from './ValidationContratPartenariat.Template.vue';
import { ApiService } from '@/services/base/ApiService';

@Component({
    ...template,
    name: 'ValidationContratPartenariat',
})
export default class ValidationContratPartenariat extends Vue {
    @Prop()
    public readonly userId: number;
    @Prop()
    public readonly validationContrat: boolean;

    /**
     * Validation ou rejet du contrat.
     * */
    public valider(): void {
        const callbackUpdate = (_: any) => this.$router.push({ name: 'administration' });
        if (this.validationContrat) {
            const apiContratPartenariat = new ApiService<boolean>(`contratPartenariat/validationContrat/${this.userId}`);
            apiContratPartenariat.put(this.validationContrat).then(callbackUpdate);
        } else {
            const apiContratPartenariat = new ApiService<boolean>(`contratPartenariat/rejetContrat/${this.userId}`);
            apiContratPartenariat.put(this.validationContrat).then(callbackUpdate);
        }
    }
}